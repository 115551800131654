/*style={{ position: 'fixed', pointerEvents: 'none', visibility: 'hidden' }}*/

.mouse-tracker {
    position: fixed;
    /* width: 224px; */
    height: auto;
    top: 0;
    left: 0;
    pointer-events: none;
    background-color: white;
    padding: 16px, 24px, 16px, 24px;
    box-shadow: 0 0 7px rgba(124, 120, 120, 0.5);
    border-radius: 8px;
    white-space: nowrap;
    visibility: hidden;

}
.tooltip {
    display: flex;
    align-items: center;
    margin: 1rem 1rem 1rem 1rem;
}

.tooltip .square {
    width: 12px;
    height: 12px;
    background-color: #374450;
    display: inline-block;
    align-items: center;
}

.tooltip .text-label {
    font-family: 'Mulish';
    font-size: 0.875rem;
    font-weight: 400;
    color: #707070;
    line-height: 15px;
    margin-left: 0.625rem;
}

.tooltip .text-value {
    font-family: 'Mulish';
    font-size: 0.875rem;
    font-weight: 600;
    color: #545454;
    line-height: 15px;
    margin-left: 0.625rem;
    
}