.ant-tooltip-content {
  color: var(--alert-text-color);
  background-color: var(--tooltip-background);
  border-radius: 4px;
}

.ant-tooltip-inner {
  background-color: transparent;
  padding: 12px 16px;
}

.ant-tooltip-arrow {
  --antd-arrow-background-color: var(--tooltip-background);
}
